import React from "react"
import { Link } from "gatsby"
import Img from 'gatsby-image'

export default props =>
<div className = "blog_feature" css = {{display:`inline-block`, position:`relative`,width:`100%`, overflow:`hidden`, borderRadius:`20px`}}>
<Link to = {props.node.frontmatter.path}>
<Img className= "blog_image" css = {{height:`100%`, filter:`brightness(40%)`}} fluid = {props.node.frontmatter.img_feature.childImageSharp.fluid}/>
<div className = "info" css={{position:`absolute`, bottom:`-50px`, paddingBottom:`50px`, background:`white`, opacity:`0.85`,width:`100%`, paddingLeft:`10px`, paddingRight:`10px`}}>
<h3 css = {{fontSize:`x-large`, marginBottom:`0.5rem`, marginTop:`0.5rem`, '@media (max-width: 750px)':{fontSize:`large`}}}>{props.node.frontmatter.title}</h3>
<p css = {{color: `#7d4aa3`, marginBottom:`0.5rem`, '@media (max-width: 750px)':{fontSize:`medium`}}}> {props.node.frontmatter.main_tag}</p>
<p css = {{fontSize: `x-small`, textTransform:`uppercase`, marginBottom:`0.5rem`}}>{props.node.frontmatter.completion_date}</p>
</div>
</Link>
</div>
