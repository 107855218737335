import React from "react"
import { graphql } from "gatsby"
import Layout from './../components/layout2.js'
import Img from 'gatsby-image'
import RandomWalk from './../components/randomwalk.js'
import { Helmet } from "react-helmet"
import Button from './../elements/button.js'
import Disqus from './../elements/disqus.js'
import Applause from './../elements/applause.js'
import EmailSignup from './../components/emailsignup.js'
import Footer from "../components/footer"

import './../css/template.css'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon
} from 'react-share';

export default function Template({data, pageContext}) {
  const { markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark
  const shareUrl = "https://brettbejcek.com" + frontmatter.path

  return (
    <Layout>
      <Helmet title={frontmatter.title}>
    <meta name="description" content={excerpt} />
    <meta name="image" content={frontmatter.img_feature.childImageSharp.fluid.src} />
    <meta property="og:type" content="article" />
    <meta property="og:title" content={frontmatter.title} />
    <meta property="og:description" content={excerpt} />
    <meta property="og:image" content={frontmatter.img_feature.childImageSharp.fluid.src} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={frontmatter.title} />
    <meta name="twitter:description" content={excerpt} />
    <meta name="twitter:image" content={frontmatter.img_feature.childImageSharp.fluid.src} />
      </Helmet>
    <Img fluid = {frontmatter.img_feature.childImageSharp.fluid} css={{width:`100%`, marginTop:`20px`, maxHeight:`30rem`, zIndex:`-100`, '@media (max-width: 750px)': {maxHeight:`20rem`}}}/>
    <div className="container" css = {{boxShaow:`0 5px 15px 0px rgba(0,0,0,0.6)`, alignItems:`center!important`, width:`100vw`, textAlign:`center`, '@media (min-width: 750px)': {marginTop:`-5rem`}}}>
      <div className="post" css = {{width:`500px`,alignItems:`center!important`, marginTop:`-10rem`, display:`inline-block`, backgroundColor:`white`, padding:`1rem`,'@media (max-width: 750px)': {boxShadow:`0 0 0 0`,width:`100%`, marginTop:`0rem`}, '@media (min-width: 750px)': {boxShadow:`0 10px 15px 0`,marginTop:`0rem`, border:`#7d4aa3 5px solid`}}}>
      <div css = {{width:`100%`, textAlign:`left`, display:`inline-block`, '@media (max-width: 750px)': {width:`100%`}}}>
        <p css={{color:`#7d4aa3`, fontWeight:`500`, marginBottom:`1rem`}}> {frontmatter.main_tag}</p>
        <h1 css = {{marginBottom:`1.5rem`}}>{frontmatter.title}</h1>
        <p css = {{textTransform:`uppercase`, fontSize:`small`, color:`#A0A0A0`}}>{frontmatter.completion_date} /// {frontmatter.location}</p>
        <div className = "share" css = {{width:`100%`, display:`flex`, }}>
        <FacebookShareButton
        url={shareUrl}
        quote={frontmatter.title}>
        <FacebookIcon
        size={40}
        round />
        </FacebookShareButton>

        <LinkedinShareButton
        url={shareUrl}
        quote={frontmatter.title}>
        <LinkedinIcon
        size={40}
        round />
        </LinkedinShareButton>

        <TwitterShareButton
        url={shareUrl}
        quote={frontmatter.title}>
        <TwitterIcon
        size={40}
        round />
        </TwitterShareButton>

        <WhatsappShareButton
        url={shareUrl}
        quote={frontmatter.title}>
        <WhatsappIcon
        size={40}
        round />
        </WhatsappShareButton>

        <RedditShareButton
        url={shareUrl}
        quote={frontmatter.title}>
        <RedditIcon
        size={40}
        round />
        </RedditShareButton>


        </div>

        <div
        css = {{marginTop:`2rem`}}
          className="post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        </div>
      </div>
    </div>
    <br/>
    <br/>

    <div css = {{width:`100%`, margin:`auto`,marginBottom:`4rem!important`, textAlign:`center`}}>
    <Applause path = {frontmatter.path}/>

    </div>
    <div css = {{width:`100%`, margin:`auto`, textAlign:`center`}}>
    {/*<Disqus id = {frontmatter.id} title = {frontmatter.title}/>*/}
    </div>


    <Button link = "/" cta = "back to home"/>
    <br/>
    <br/>
    <hr css = {{margin:`auto`, maxWidth:`700px`, backgroundColor: `#7d4aa3`}}/>
    <br/>
    <div css = {{margin:`auto`}}>
    <h3 css = {{textAlign:`center`}}>Other posts you may like</h3>
    <div className = "randomwalk" css = {{maxWidth:`700px`,  marginLeft:`auto`, marginRight:`auto`,marginTop:`2rem`, display:`grid`, gridTemplateColumns: `repeat(3, 1fr)`, gridGap: `2rem`,'@media (max-width: 750px)': {gridTemplateColumns: `repeat(1, 1fr)`, width:`50%`}}}>
    <RandomWalk node = {pageContext.left}/>
    <RandomWalk node = {pageContext.middle}/>
    <RandomWalk node = {pageContext.right}/>
    </div>
    </div>
    <br/>
    <br/>
    <div css={{maxWidth:`700px`, margin:`auto`}}>
    <EmailSignup/>
    <Footer/>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength:250)
      frontmatter {
        completion_date(formatString: "MMMM DD, YYYY")
        id
        path
        title
        location
        main_tag
        img_feature {
          childImageSharp {
            fluid(maxWidth:2000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          } }
      }
    }
  }
`
